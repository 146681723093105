.QuoteDemo-container {
    position: absolute;
    border: solid 1px #bdbdbd;
}

.QuoteDemo-storage-area {
    position: absolute;
    border: solid 1px #bdbdbd;
    background-image: linear-gradient(45deg, #f5f5f5 25%, #e0e0e0 25%, #e0e0e0 50%, #f5f5f5 50%, #f5f5f5 75%, #e0e0e0 75%, #e0e0e0 100%);
    background-size: 14.14px 14.14px;
}

.QuoteDemo-reserved-area {
    position: absolute;
    border: solid 6px #ffcdd2;
    background-image: linear-gradient(45deg, #ffcdd2 25%, #eeeeee 25%, #eeeeee 50%, #ffcdd2 50%, #ffcdd2 75%, #eeeeee 75%, #eeeeee 100%);
    background-size: 14.14px 14.14px;
}

.QuoteDemo-container-badge {
    border-radius: .3rem;
    padding: 5px 14px;
}

.QuoteDemo-table-responsive {
    background-image: linear-gradient(to right, white, white), linear-gradient(to right, white, white), linear-gradient(to right, rgba(0, 0, 20, .20), rgba(255, 255, 255, 0)), linear-gradient(to left, rgba(0, 0, 20, .20), rgba(255, 255, 255, 0));
    background-position: left center, right center, left center, right center;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;
    background-attachment: local, local, scroll, scroll;
}