:root {
    --app-height: 100%;
  }
  
html,
body {
    padding: 0;
    margin: 0;
    overflow: hidden;
    /* width: 100vw;
    height: 100vh; */
    height: var(--app-height);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #172b4d;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Styling the Google Autocomplete and SearchBox widgets  */
.pac-item {
    padding: 0.85rem 1rem;
    font-size: 0.85rem;
}

.pac-item-query {
    font-size: 0.85rem;
    padding-right: 0.7rem;
}

.pac-container {
    z-index: 1100;
    box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
}

/* Bootstrap overrides using media queries for responsive font sizes and margins */

@media screen and (max-width: 576px) {
    /* Typography */
    .display-4 {
        font-size: 2.3rem !important; /* Bootstrap default: 3.5rem */
    }

    .h4 {
        font-size: 1.5rem !important; /* Bootstrap default: 1.5rem */
    }
  
    /* Margins */
    .mb-5, .my-5 {
        margin-bottom: 1.5rem !important; /* Bootstrap default: 3rem */
    }

    /* Forms */
    .form-control-lg {
        font-size: 1.125rem !important; /* Bootstrap default: 1.25rem */
    }
}

.WS-card-grey {
    background-color: #f5f8fa;
    margin-bottom: 1rem;
    color: #57606a;
    padding: 0.5rem 1rem;
}

.WS-card-grey:last-child {
    margin-bottom: 0;
}

.WS-card-dark {
    border: none;
    background: #dee2e6;
    margin-bottom: 1rem;
    border-radius: 0.5rem;
    padding: 1rem;
    box-shadow: 0 0 20px 0 rgb(76 87 125 / 4%);
}

.WS-card-dark:last-child {
    margin-bottom: 0;
}

.WS-card {
    border: 1px solid #d6d9db;
    background: #fff;
    margin-bottom: 1rem;
    border-radius: 0.25rem;
    padding: 1.5rem;
    /* box-shadow: 0 0 20px 0 rgb(76 87 125 / 4%); */
}

.WS-card:last-child {
    margin-bottom: 0;
}

.WS-btn-round {
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    border-radius: 50%;
    padding: 0 !important;
    background: #dee2e6;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    /* color: #6c757d; */
    color: inherit;
    font-weight: 600;
}

.WS-btn-round-trans {
    background: transparent !important;
}

.WS-clickable {
    cursor: pointer;
}

.WS-table td {
    background-color: #fff;
    border-color: #f6f9fc;    
    border-bottom-width: 4px;
    /* border-right-width: 4px; */
    white-space: nowrap;
}

.WS-table td:first-child {
    border-left-width: 4px;
    /* border-left-color: #3699ff; */
}

/* .WS-table td:last-child {
    border-right-width: 0px;
} */

.WS-table th {
    /* text-transform: uppercase; */
    /* color: #57606a; */
    font-weight: 700;
    font-size: 0.8rem;
    border-width: 0;
    white-space: nowrap;
}

.WS-bg-active {
    background-color: #3699ff !important;
}

.WS-bg-done {
    background-color: #1bc5bd !important;
}

/* BUTTONS */
.WS-btn-min-width {
    min-width: 120px;
}

.WS-button-toggle {
    background-color: #ebecf0;
    border: #ebecf0;
    color: #42526e;
    font-weight: 500;
    font-size: 0.95rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
}

.WS-button-toggle:hover {
    background-color: #e2e3e6;
    border: #e2e3e6;
    color: #42526e;
}

.WS-button-toggle-active {
    background-color: #253858;
    border: #253858;
    color: #fff;
}

.WS-button-toggle-active:hover {
    background-color: #253858;
    border: #253858;
    color: #fff;
    cursor: default;
}

.WS-subTitle {
    /* text-transform: uppercase; */
    /* color: #57606a; */
    font-weight: 600;
    font-size: 0.9rem;
    margin-bottom: 0.2rem;
    display: inline-block;
}

.WS-content-with-message {
    /* height: calc(100vh - 62px - 36px) !important; */
    /* height: calc(var(--app-height) - 62px - 36px) !important; */
    height: calc(var(--app-height) - 62px - 57px - 36px) !important;
}

.WS-small-text {
    font-size: 0.9rem;
}

.WS-no-decoration {
    list-style: none;
    padding: 0;
}

.WS-list-label {
    font-weight: 600;
    color: #42526e;
    font-size: 0.9em;
}

@media screen and (max-width: 992px) {
    .WS-margin-top {
        margin-top: 2rem !important;
    }
}

/* .nav-scroller {
	position: relative;
	z-index: 2;
	height: 2.75rem;
	overflow-y: hidden;
}

.nav-scroller .nav {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	padding-bottom: 1rem;
	margin-top: 0;
	overflow-x: auto;
	color: rgba(255, 255, 255, .75);
	text-align: center;
	white-space: nowrap;
	-webkit-overflow-scrolling: touch;
} */

/* =================== */
/* Bootstrap overrides */
/* =================== */

/* TABLES */

.table td, th{
    padding: 1rem 0.75rem;
    font-size: 0.85rem;
}

/* BUTTONS */

.btn {
    /* display: flex; */
    /* align-items: center; */
    padding: 0.3rem 0.75rem;
}

.btn-primary {
    background-color: #3699ff;
    border-color: #3699ff;
}

.btn-primary:hover, .btn-primary:focus {
    background-color: #2c89e8;
    border-color: #2c89e8;
}

.btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #3699ff;
    border-color: #3699ff;
}

.btn-success {
    background-color: #1bc5bd;
    border: #1bc5bd;
}

.btn-success:hover, .btn-success:focus {
    background-color: #19b7b0;
    border-color: #19b7b0;
}  

.btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #42d2cb;
    border-color: #42d2cb;
}

/* FORMS */

/* .form-label {
    font-size: 0.9rem;
} */

.form-text {
    /* color: #999; */
    color: #57606a;
    font-size: 0.8rem;
}

.form-check-input:checked {
    background-color: #3699ff;
    border-color: #3699ff;
}

.form-label {
    /* text-transform: uppercase; */
    /* color: #57606a; */
    font-weight: 600;
    font-size: 0.9rem;
}

.input-group-text {
    font-size: 0.85rem;
}

/* PROGRESS BAR  */

.progress-bar {
    background-color: #1bc5bd;
}

.progress {
    height: 5px !important;
}

/* MODAL */

.modal-content-vh-100 {
    height: 100vh;
}

.modal-header {
    padding: 1rem 2rem;
}

.modal-footer {
    padding: 1rem 2rem;
}

.modal-footer>* {
    margin: 0;
}

@media screen and (max-width: 992px) {
    .modal-header {
        padding: 1rem 1.25rem
    }
    
    .modal-footer { 
        padding: 1rem 1.25rem
    }
}

.card {
    /* border-radius: 0.5rem; */
    border-color: #d6d9db;
}

.alert {
    font-size: 0.9rem;
}