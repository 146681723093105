.drawer {
    width: 258px;
}

.header {
    color: #f5f6f6;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    height: 62px;
    background-color: #3699ff;
    position: fixed;
    top: 0;
    left: 0;
    width: 257px;
    display: flex;
    align-items: center;
    font-size: 1.0625rem;
    font-weight: 500;
}

.beta-main {
    display: inline-block;
    margin-left: 8px;
}

.beta {
    font-size: 0.6em;
    position: relative;
    top: -6px;
    padding: .25em .65em .35em .65em !important;
}