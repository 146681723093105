.Login {
    /* min-height: 100vh; */
    height: var(--app-height);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f5f6f6;
    text-align: center;
    padding: 40px 0;
}

.Login-company-name-waste {
    background-color: #343a40 !important; 
    color: #f5f6f6 !important; 
    padding: 0.15em 0.25em;
}

.Login-company-name-saver {
    font-weight: bold;
    color: #343a40;
}

.Login-form {
    width: 100%;
    max-width: 340px;
    margin: 0 auto;
}

.Login-form .form-control {
    padding-top: 24px;
    padding-bottom: 24px;
}

.Login-button-submit {
    width: 100%;
    max-width: 340px;
}