.header {
    color: #f5f6f6;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    height: 62px;
    background-color: #3699ff;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 500;
    display: flex;
    align-items: center;
}

@media screen and (max-width: 540px) {
    .header {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }
}

.company-name {
    font-size: 1.0625rem;
    font-weight: 700;
    margin-right: auto;
}

.beta-main {
    display: inline-block;
    margin-left: 8px;
}

.beta {
    font-size: 0.6em;
    position: relative;
    top: -6px;
    padding: .25em .65em .35em .65em !important;
}

.dropdown {
    margin-top: 10px !important;
}

.menu-item {
    padding: .5rem 1rem;
}

.menu-item svg {
    margin-right: 10px;
    opacity: 0.75;
    margin-top: -1px;
}

.header a {
    color: #f5f6f6 !important;
    text-decoration: none;
}

.drawer-toggle {
    margin-top: -2px;
    margin-right: 10px;
    cursor: pointer;
    margin-right: auto;
}

.svg-link {
    cursor: pointer;
}