.alert {
    margin-left: 257px;
    display: flex;
    justify-content: space-between;
    padding: 0.75rem 0.7rem 0.75rem 1.25rem;
    border-bottom: 1px solid transparent;
    border-left: 1px solid #e0e0e0 !important;
    font-size: 0.9rem;
    height: 57px;
}

@media screen and (max-width: 576px) {
    .alert {
        margin-left: 0;
        border-left: none;
    }
}

.success {
    color: #0f5132;
    background-color: #d1e7dd;
    border-color: #badbcc;
}

.warning {
    color: #664d03;
    background-color: #fff3cd;
    border-color: #ffecb5;
}

.danger {
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
}