.title {
    padding: 12px 28px 12px 32px;
    width: 100%;
    text-decoration: none;
    color: #424242;
    font-weight: 400;
    font-size: 0.7rem;
    text-transform: uppercase;
}

.menu {
    overflow: auto;
    position: fixed;
    top: 62px;
    left: 0;
    bottom: 0;
    width: 257px;
    padding-top: 20px;
    text-align: left;
    border: none;
    /* height: calc(100vh - 62px - 36px); */
    height: calc(var(--app-height) - 62px - 36px);
}

.menu ul {
    padding: 0;
    list-style-type: none;
}

.menu li {
    display: flex;
}

.menu li a {
    padding: 12px 28px 12px 32px;
    width: 100%;
    text-decoration: none;
    /* color: #424242; */
    color: #3f4254;
    font-weight: 400;
    font-size: 0.9rem;
}

.menu li a:hover {
    background-color: #eeeeee;
}

.menu svg {
    margin-right: 16px;
    /* opacity: 0.5; */
    margin-top: -2px;
}

.menu-item-active {
    background-color: #f5f5f5;
    color: #3699ff !important;
}

.menu-item-active:hover {
    background-color: #f5f5f5 !important;
}