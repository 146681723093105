.card {
    background-color: #fff;
    border-radius: 0.25rem;
    padding: 1rem;
    margin-top: 1rem;
}

.title {
    /* font-size: 0.85rem; */
    font-weight: 500;
    color: #212529;
}

.subtitle {
    font-size: 0.85rem;
}

.form-control-min-size {
    min-width: 70px !important;
}

.set-value-form {
    border-color: #009688 !important;
    background-color: #69f0ae !important;
}

.set-value {
    color: #00c853;
    font-weight: 800;
}

.btn-set-value {
    background: #dee2e6;
    color: #6c757d;
    align-items: center;
    justify-content: center;
    display: flex;
}

.no-border-bottom {
    border-bottom: none !important;
}