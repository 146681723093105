.footer {
    font-size: 0.75rem;
    color: #fff;
    background-color: #37474f;
    height: 36px;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.25rem;
}

.footer ul {
    padding: 0;
    list-style-type: none;
    text-align: right;
    margin: 0 !important;
}

.footer li {
    display: inline-block;
}

.footer li a {
    font-weight: 500;
    text-decoration: none;
    color: #eceff1;
}

.left li a {
    padding-right: 20px;
}

.right li a {
    padding-left: 20px;
}

.footer li a:hover {
    color: #fff;
}

.copy {
    color: #b0bec5;
}