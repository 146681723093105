.AccessArea-access-area {
    background-color:#eee;
    margin: auto;
    position: relative;
}

.AccessArea-doorway {
    position: absolute;
    background-color: #e57373;
    z-index: 100;
}

.AccessArea-rotated-heading {
    display: block;
    position: relative;
}

.AccessArea-rotated-heading h5 {
    position: absolute;
    /* top: 130px; */
    left: -33px;
    transform-origin: 0 0;
    transform: rotate(-90deg);
}