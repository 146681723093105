.step-badge {
    background-color: #e3e6ef;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 300;
}

.title {
    font-size: 0.95rem;
    font-weight: 500;
}

.subTitle {
    font-size: 0.8rem;
    color: #424242;
}

.row {
    margin-bottom: 2rem;
}

.row:last-child {
    margin-bottom: 0;
}