.steps {
    padding: 2rem;
    position: fixed;
    width: 332px;
}

.form {
    padding: 2rem 2rem 2rem 0;
    margin-left: 332px;
}

@media screen and (max-width: 992px) {
    .steps {
        padding: 1rem 1.25rem 0 1.25rem;
        position: relative;
        width: 100%;
    }

    .form {
        padding: 1.5rem 1.25rem 2rem 1.25rem;
        margin-left: 0;
    }
}
