.content {
    margin-left: 257px;
    background-color: rgb(247, 249, 252);
    padding: 0;
    border-left: 1px solid #e0e0e0;
    /* height: calc(100vh - 62px - 57px - 36px); */
    /* height: calc(var(--app-height) - 62px - 57px - 36px); */
    height: calc(var(--app-height) - 62px - 36px);
    overflow: auto;
}

.content-body {
    padding: 1.75rem 2rem;
}

@media screen and (max-width: 576px) {
    .content {
        margin-left: 0;
        border: none;
    }

    .content-body {
        padding: 1rem 1.25rem;
    }
}